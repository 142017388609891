var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "body add-card" }, [
    _c(
      "div",
      { staticClass: "form" },
      [
        _c(
          "el-form",
          {
            ref: "addForm",
            staticClass: "demo-form dialog-form",
            attrs: {
              model: _vm.addForm,
              rules: _vm.rules,
              "label-width": "82px",
              "label-position": "left"
            }
          },
          [
            _c(
              "el-form-item",
              { attrs: { label: "IC卡号", prop: "cardNo" } },
              [
                _c("el-input", {
                  attrs: { type: "text" },
                  model: {
                    value: _vm.addForm.cardNo,
                    callback: function($$v) {
                      _vm.$set(_vm.addForm, "cardNo", $$v)
                    },
                    expression: "addForm.cardNo"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "物理卡号", prop: "physicalNo" } },
              [
                _c("el-input", {
                  attrs: { type: "text" },
                  model: {
                    value: _vm.addForm.physicalNo,
                    callback: function($$v) {
                      _vm.$set(_vm.addForm, "physicalNo", $$v)
                    },
                    expression: "addForm.physicalNo"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "厂家", prop: "makerId" } },
              [
                _c(
                  "el-select",
                  {
                    attrs: { clearable: "" },
                    model: {
                      value: _vm.addForm.makerId,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.addForm,
                          "makerId",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "addForm.makerId"
                    }
                  },
                  _vm._l(_vm.makerIdList, function(i) {
                    return _c("el-option", {
                      key: i.dictCode,
                      attrs: { label: i.dictValue, value: i.dictCode }
                    })
                  }),
                  1
                )
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "所属公司", prop: "companyId" } },
              [
                _c("companySelect", {
                  ref: "companySelect",
                  attrs: {
                    clearable: true,
                    companyTree: _vm.companyList,
                    valueName: _vm.valueNameAdd,
                    value: _vm.valueAdd
                  },
                  on: { getValue: _vm.getAddGroupId }
                })
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }