<template>
  <div class="ICCard-management" ref="ICCardManagement">
    <div class="function-buttons" ref="functionButtons">
      <el-button
        type="primary"
        size="small"
        @click="onAddCard"
        v-if="$store.state.menu.nowMenuList.indexOf('新增') >= 0"
        >新增</el-button
      >
      <el-button
        type="primary"
        size="small"
        @click="onExport"
        v-if="$store.state.menu.nowMenuList.indexOf('模板下载') >= 0"
        >模板下载</el-button
      >
      <!-- <uploadFile
        @uploadSuccess="uploadSuccess"
        url="/base/device/import"
        class="import"
      ></uploadFile> -->
    </div>
    <div class="form-area" ref="form">
      <el-form
        :inline="true"
        :model="form"
        class="demo-form-inline"
        label-position="left"
        label-width="72px"
      >
        <el-form-item label="司机姓名：" prop="driverName">
          <el-input
            v-model="form.driverName"
            placeholder="请输入司机姓名"
          ></el-input>
        </el-form-item>
        <el-form-item label="驾驶证号：" prop="drivingLicense">
          <el-input
            v-model="form.drivingLicense"
            placeholder="请输入驾驶证号"
          ></el-input>
        </el-form-item>
        <el-form-item label="IC卡号：" prop="cardNo">
          <el-input v-model="form.cardNo" placeholder="请输入IC卡号"></el-input>
        </el-form-item>
        <el-form-item label="IC卡厂商：" prop="makerId">
          <el-select clearable v-model.trim="form.makerId">
            <el-option
              v-for="item in makerIdList"
              :label="item.dictValue"
              :value="item.dictCode"
              :key="item.dictCode"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="制卡状态:" prop="cardStatus">
          <el-select clearable v-model="form.cardStatus" placeholder="请选择">
            <el-option
              v-for="item in cardStatusList"
              :label="item.dictValue"
              :value="item.dictCode"
              :key="item.dictCode"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="onSearch"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <el-table :data="tableData" stripe :height="tableHeight">
      <el-table-column label="序号" type="index" width="60"></el-table-column>
      <el-table-column
        prop="cardNo"
        label="IC卡号"
        width="160"
      ></el-table-column>
      <el-table-column
        prop="physicalNo"
        label="物理卡号"
        width="160"
      ></el-table-column>
      <el-table-column
        prop="makerId"
        label="厂商"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">{{
          makerIdObj[Number(scope.row.makerId)]
        }}</template>
      </el-table-column>
      <el-table-column
        prop="companyName"
        label="所属公司"
        width="200"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column prop="cardStatus" label="制卡状态">
        <template slot-scope="scope">{{
          cardStatusObj[Number(scope.row.cardStatus)]
        }}</template>
      </el-table-column>
      <el-table-column prop="driverName" label="司机姓名"></el-table-column>
      <el-table-column
        prop="drivingLicense"
        label="驾驶证号"
        width="180"
      ></el-table-column>
      <el-table-column label="操作" width="140">
        <template slot-scope="scope">
          <!-- <el-button
            type="text"
            size="small"
            @click="onMakeCard(scope.row)"
            v-if="$store.state.menu.nowMenuList.indexOf('制卡') >= 0 && scope.row.cardStatus !=='2'"
          >制卡</el-button> -->
          <el-button
            @click="onUpdateCard(scope.row)"
            type="text"
            size="small"
            sort="primary"
            v-if="
              $store.state.menu.nowMenuList.indexOf('编辑') >= 0 &&
              scope.row.cardStatus === '1'
            "
            >编辑</el-button
          >
          <el-button
            type="text"
            size="small"
            sort="danger"
            @click="onDeleteCard(scope.row)"
            v-if="
              $store.state.menu.nowMenuList.indexOf('遗失') >= 0 &&
              scope.row.cardStatus !== '2'
            "
            >遗失</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination" ref="pagination">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :page-size="form.pageSize"
        :current-page="form.currentPage"
        @current-change="onCurrentChange"
        @size-change="onSizeChange"
      ></el-pagination>
    </div>

    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      v-if="dialogVisible"
      @close="onDialogClose"
      width="30%"
    >
      <addCard
        ref="addCard"
        :item="addForm"
        @close="onDialogClose"
        @onLoading="onLoading"
      />
      <span slot="footer" class="dialog-footer">
        <el-button type="default" size="small" @click="dialogVisible = false"
          >取消</el-button
        >
        <el-button
          type="primary"
          size="small"
          :loading="editLoading"
          @click="onSave"
          >保存</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { formatDict } from '@/common/utils/index'
import { getCardList, cardLoss, queryDictsByCodes } from '@/api/lib/api.js'
import uploadFile from '@/components/uploadFile/uploadFile.vue'
import addCard from './components/addCard'
export default {
  name: 'ICCardManagement',
  components: {
    uploadFile,
    addCard
  },
  data() {
    var checkRangeNumber = (rule, value, callback) => {
      const regEn = /^[0-9]{0,20}$/
      if (value === '') {
        callback()
      } else {
        if (!regEn.test(value)) {
          callback(new Error('请输入不超过20位数字'))
        } else {
          callback()
        }
      }
    }
    return {
      tableHeight: 0,
      tableData: [],
      form: {
        makerId: null,
        cardStatus: null,
        driverName: null,
        drivingLicense: null,
        cardNo: null,
        currentPage: 1,
        pageSize: 10
      },
      addForm: {
        serialNo: '',
        deviceNo: '',
        sim: '',
        producerId: '',
        deviceType: '',
        deviceModel: '',
        companyId: ''
      },
      makeForm: {
        id: '',
        driverId: ''
      },
      rules: {
        serialNo: [
          { required: true, message: '请输入IC卡自编号', trigger: 'blur' },
          { validator: checkRangeNumber, trigger: 'blur' }
        ],
        deviceNo: [
          { required: true, message: '请输入设备号', trigger: 'blur' },
          { validator: checkRangeNumber, trigger: 'blur' }
        ],
        sim: [
          { required: true, message: '请输入SIM卡号', trigger: 'blur' },
          { validator: checkRangeNumber, trigger: 'blur' }
        ],
        producerId: [
          { required: true, message: '请选择生产商', trigger: 'change' }
        ],
        deviceType: [
          { required: true, message: '请选择设备类型', trigger: 'change' }
        ],
        deviceModel: [
          { required: true, message: '请选择设备型号', trigger: 'change' }
        ],
        companyId: [
          { required: true, message: '请选择所属公司', trigger: 'blur' }
        ]
      },
      total: 250,
      title: '',
      makerIdList: [],
      cardStatusList: [],
      makerIdObj: null,
      cardStatusObj: null,
      dialogVisible: false,
      makeCardVisible: false,
      editLoading: false
    }
  },
  methods: {
    // 计算表格高度
    computeHeight() {
      const wholeHeight = this.$refs.ICCardManagement.clientHeight
      const buttonsHeight = this.$refs.functionButtons.clientHeight
      const formHeight = this.$refs.form.clientHeight
      const paginationHeight = this.$refs.pagination.clientHeight
      this.tableHeight =
        wholeHeight -
        16 -
        buttonsHeight -
        16 -
        formHeight -
        paginationHeight -
        10 -
        16
    },
    onSearch() {
      this.searchLoading = true
      this.form.currentPage = 1
      this.form.pageSize = 10
      this.getListByField()
    },
    getListByField() {
      getCardList(this.form).then((res) => {
        if (res.code === 1000) {
          this.tableData = res.data.list
          this.total = res.data.total
        } else {
          this.tableData = []
          this.total = 0
          this.$message.error(res.msg)
        }
      })
    },
    // 当前页码改变
    onCurrentChange(page) {
      this.form.currentPage = page
      this.getListByField()
    },
    // 当前条数改变
    onSizeChange(size) {
      this.form.pageSize = size
      this.form.currentPage = 1
      this.getListByField()
    },
    // 下载模板
    onExport() {},
    // 新增IC卡
    onAddCard() {
      this.title = '新增IC卡'
      this.addForm = null
      this.dialogVisible = true
    },
    // 点击表格查看按钮
    onUpdateCard(row) {
      this.title = '修改IC卡信息'
      this.addForm = { ...row }
      this.dialogVisible = true
    },
    // 制卡
    onMakeCard(row) {
      this.makeForm = { ...row }
      this.makeCardVisible = true
    },
    // 删除IC卡数据
    onDeleteCard(row) {
      const h = this.$createElement
      this.$msgbox({
        title: '消息',
        type: 'warning',
        message: h('p', null, [h('span', null, '确认该卡片遗失？')]),
        showCancelButton: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(() => {
          cardLoss({ id: row.id }).then((res) => {
            if (res.code === 1000) {
              this.$message.success('操作成功')
              this.onSearch()
            } else {
              this.$message.error(res.msg)
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            showClose: true,
            message: '已取消卡片遗失'
          })
        })
    },

    // 关闭弹窗
    onDialogClose(flag) {
      this.dialogVisible = false
      if (flag) {
        if (this.title.indexOf('新增') > -1) {
          this.onSearch()
        } else {
          this.getListByField()
        }
      }
      this.editLoading = false
    },
    // 关闭制卡弹窗
    onMakeCardDialogClose(flag) {
      this.makeCardVisible = false
      if (flag) {
        if (this.title.indexOf('新增') > -1) {
          this.onSearch()
        } else {
          this.getListByField()
        }
      }
      this.editLoading = false
    },
    //  保存
    onSave() {
      this.$refs.addCard.onSave()
    },
    onLoading(flag) {
      this.editLoading = flag
    },
    // 制卡
    onSaveMakeCard() {
      this.$refs.makeCard.onSave()
    },
    // 关闭弹窗
    closeDialog(flag) {
      this.dialogVisible = false
      if (flag) {
        if (this.title.indexOf('新增') > -1) {
          this.onSearch()
        } else {
          this.getListByField()
        }
      }
    },
    getGroupId(v) {
      this.form.companyIds = v
    },
    getAddGroupId(v) {
      this.addForm.companyId = v
    },
    // 文件上传成功
    uploadSuccess() {
      this.onSearch()
    },
    //获取字典值
    getDicts() {
      queryDictsByCodes({ parentCodes: 'SBCJ,ZKZT' }).then((res) => {
        if (res.code === 1000) {
          this.makerIdList = res.data.SBCJ
          this.cardStatusList = res.data.ZKZT
          this.makerIdObj = formatDict(this.makerIdList)
          this.cardStatusObj = formatDict(this.cardStatusList)
        }
      })
    }
  },
  created() {
    this.getDicts()
  },
  mounted() {
    this.$nextTick(() => {
      this.computeHeight()
      this.onSearch()
      window.addEventListener('resize', this.computeHeight, false)
    })
  },
  destroyed() {
    window.removeEventListener('resize', this.computeHeight)
  }
}
</script>

<style lang="scss" scoped>
.ICCard-management {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding: 16px 20px;
  box-sizing: border-box;
  @include themify() {
  }
}

.theme-project-resourse {
  .ICCard-management {
    background: #ffffff;
    box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  }
}
</style>
